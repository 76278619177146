import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
declare var $ : any


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: .5}),
          animate('250ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: .5}),
          animate('0ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class HomeComponent implements OnInit {

  slider_default: number = 1;
  srcImage: string = '';

  constructor() { }

  ngOnInit(): void {
   
  }

  sliderChange(data: any){
    this.slider_default = data
    switch (data) {
      case 1:
        this.srcImage = "assets/images/home/slider/test1.jpg" 
      break;
      case 2:
        this.srcImage = "assets/images/home/slider/test2.jpg" 
      break;
      case 3:
        this.srcImage = "assets/images/home/slider/test3.jpg" 
      break;
      case 4:
        this.srcImage = "assets/images/home/slider/test4.jpg" 
        break;
      default:
      break;
    }
  }

}
