// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: "https://services.fidelityapps.mx/api/v1/branch-offices",
  api_key: "AIzaSyClbwac5I9q77i3nTOa8gELcI-m3YXZ_Xs",
  smToken: '$2y$10$LGqtRWUloKQmAt.jcUldc.PmS5LJ6l.WU2w1G4PBOTLMVKZRNQFPS',
  userToken: '$2y$10$EZlXnFrhUc4aRVwjy5/v0.3JPdFNjyeANDc64YXJ5eHD8pMsv93Q.',
  img_url: 'https://services.fidelityapps.mx/',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
