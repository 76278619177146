<div class="container align">
        <h4 class="titulo">AVISO DE PRIVACIDAD</h4>

        <p>En cumplimiento con la Ley Federal de Protección de Datos Personales   en Posesión de Particulares, ROCKBLADE informa a clientes y proveedores que los datos personales que nos proporcionen serán tratados de forma confidencial, administrados y resguardados en bases de datos exclusivas de esta empresa, con la finalidad de garantizar la privacidad
        y seguridad de los mismos.</p>
        
        <p><span>¿QUÉ INFORMACIÓN RECOPILAMOS?</span><br>
        Los datos que la página web, a nombre de ROCKBLADE, podrá recabar serán los siguientes:</p>
        <ul>
            <li>Nombre</li>
            <li>Teléfono</li>
            <li>Correo electrónico</li>
            <li>Comentarios</li>
            <li>Cookies</li>
        </ul>
        
        <p>
        <span>
            ¿CÓMO UTILIZAMOS O COMPARTIMOS LA INFORMACIÓN RECOPILADA?</span><br>
            Los Datos Personales que el cliente nos proporciona por correo  y sitio web podrán ser compartidos entre nuestro personal y/o sucursales dentro y/o fuera del territorio Mexicano y serán utilizados para contactarte en caso para asesorarte en la información solicitada de tu parte y para brindar un mejor servicio al momento de la atención así como para evaluar y mejorar continuamente los productos y servicios que ofrecemos, así como mantenerte informado de nuevos productos, materiales y promociones.
        </p>
        <p>
            Le recordamos que, en materia de Protección de Datos Personales, usted podrá ejercer en cualquier momento sus derechos en cuanto a Acceso, Rectificación, Cancelación y Oposición (Derechos ARCO) para lo cual ponemos a su disposición los correos electrónicos: 
            <a href="mailto:hola@rockblade.com">hola@rockblade.com</a>
        </p>

        <p>En caso de existir alguna modificación este Aviso de Privacidad se hará de su conocimiento oportuno en nuestro sitio de Internet: <a href="http://rockbladetires.com/" target="_blank">http://rockbladetires.com/</a></p> 

</div>