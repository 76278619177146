import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  //url = "http://localhost/api/";
  data_llantas = [
    {
      name: "515",
      category: "Automovil",
      description: "El compuesto de la banda contiene silicio que mejora el agarre en carretera mojada, reduce la resistencia proporcionando seguridad y reduciendo combustible.",
      image: "515/515.png",
      background: "background.png",
      slider_image:  ["515/515-detail-01.jpg", "515/515-detail-02.jpg", "515/515-detail-03.jpg" ],
      features: [
        {
         text: "COMPUESTO CONTENEDOR<br> DE SILICIO",
         description: "Mejora el agarre en caminos mojados brindando seguridad en el viaje",
         image: "515/515-01.png"
        },
        {
          text: 'ESTAMPADO<br> "SKIVING"',
          description: "Lineas rectas que absorben sacudidas del camino para un viaje cómodo",
          image: "515/515-02.png"
        },
        {
          text: "DISEÑO<br> SILENCIOSO",
          description: "Hilos simulados en computadora con excelente silencia y cómodo viaje",
          image: "515/515-03.png"
         },
        {
          text: "ESTAMPADO<br> BLOQUE GRUESO",
          description: "Bloques gruesos en el exterior asegurando gran rigidez",
          image: "515/515-04.png"
        },
      ],
      table : [
        {
          size: "145/70R12",
          li_si: "69T",
          depth: "6,6",
          utqg: "420/A/A"
        },
        {
          size:  "155/70R12",
          li_si: "73T",
          depth: "6,6",
          utqg:  "420/A/A"
        },
        {
          size:  "165/70R12",
          li_si: "77T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "145/70R13",
          li_si: "71T",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "145/80R13",
          li_si: "75T",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "155/65R13",
          li_si: "73T",
          depth: "6,6",
          utqg:  "420/A/A",
        },
        {
          size:  "155/70R13",
          li_si: "75T",
          depth: "6,6",
          utqg:  "420/A/A",
        },
        {
          size:  "155/80R13",
          li_si: "79T",
          depth: "6,6",
          utqg:  "420/A/A",
        },
        {
          size:  "165/65R13",
          li_si: "77T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "165/80R13",
          li_si: "83T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "165/70R13",
          li_si: "79T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "175/60R13",
          li_si: "77T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "175/65R13",
          li_si: "80T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "175/70R13",
          li_si: "82T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "185/70R13",
          li_si: "86T",
          depth: "7,5",
          utqg:  "420/A/A",
        },
        {
          size:  "155/65R14",
          li_si: "75T",
          depth: "6,6",
          utqg:  "420/A/A",
        },
        {
          size:  "155/70R14",
          li_si: "77T",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "165/60R14",
          li_si: "75H",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "165/65R14",
          li_si: "79T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "165/70R14",
          li_si: "81T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "175/60R14",
          li_si: "79H",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "175/65R14",
          li_si: "82T",
          depth: "7,4",
          utqg:  "420/A/A",
        },
        {
          size:  "175/65R14",
          li_si: "82H",
          depth: "7,4",
          utqg:  "420/A/A",
        },
        {
          size:  "175/65R14",
          li_si: "86T",
          depth: "7,4",
          utqg:  "420/A/A",
        },
        {
          size:  "175/70R14",
          li_si: "84T",
          depth: "7,2",
          utqg:  "420/A/A",
        },
        {
          size:  "185/55R14",
          li_si: "80H",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/60R14",
          li_si: "82H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/65R14",
          li_si: "86T",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/65R14",
          li_si: "86H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/70R14",
          li_si: "88H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/60R14",
          li_si: "86H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/65R14",
          li_si: "89H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/70R14",
          li_si: "91H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/60R14",
          li_si: "88H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/70R14",
          li_si: "98H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/70R14",
          li_si: "95H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/70R14",
          li_si: "96H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "145/65R15",
          li_si: "72T",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "165/60R15",
          li_si: "81H XL",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "165/65R15",
          li_si: "81T",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "175/55R15",
          li_si: "77V",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "175/60R15",
          li_si: "81H",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "175/65R15",
          li_si: "84H",
          depth: "7,4",
          utqg:  "420/A/A",
        },
        {
          size:  "185/55R15",
          li_si: "82H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/55R15",
          li_si: "82V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/60R15",
          li_si: "88H XL",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/65R15",
          li_si: "88T",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/65R15",
          li_si: "88H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/45R15",
          li_si: "78V",
          depth: "7,6",
          utqg:  "420/A/A",
        },
        {
          size:  "195/50R15",
          li_si: "82H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/50R15",
          li_si: "82V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/55R15",
          li_si: "85H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/55R15",
          li_si: "85V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/60R15",
          li_si: "88V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/60R15",
          li_si: "88H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/65R15",
          li_si: "91H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/65R15",
          li_si: "91V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/60R15",
          li_si: "91V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/65R15",
          li_si: "94V",
          depth: "8,5",
          utqg:  "420/A/A",
        },
        {
          size:  "205/65R15",
          li_si: "94H",
          depth: "8,5",
          utqg:  "420/A/A",
        },
        {
          size:  "205/70R15",
          li_si: "96H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/60R15",
          li_si: "94H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/65R15",
          li_si: "96H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/70R15",
          li_si: "98H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/60R15",
          li_si: "96V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/70R15",
          li_si: "100H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "175/50R16",
          li_si: "77V",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/50R16",
          li_si: "81V",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "185/55R16",
          li_si: "83V",
          depth: "6,8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/50R16",
          li_si: "84V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/55R16",
          li_si: "87V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "195/60R16",
          li_si: "89H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/55R16",
          li_si: "91H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/55R16",
          li_si: "91V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/60R16",
          li_si: "92H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/60R16",
          li_si: "92V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "205/65R16",
          li_si: "95H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/55R16",
          li_si: "93V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/60R16",
          li_si: "95V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/60R16",
          li_si: "99H XL",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/65R16",
          li_si: "98H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/55R16",
          li_si: "95V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/60R16",
          li_si: "98H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/60R16",
          li_si: "98V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/65R16",
          li_si: "100T",
          depth: "",
          utqg:  "420/A/A",
        },
        {
          size:  "235/60R16",
          li_si: "100H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "235/65R16",
          li_si: "103T",
          depth: "",
          utqg:  "420/A/A",
        },
        {
          size:  "215/55R17",
          li_si: "98H",
          depth: "7,7",
          utqg:  "420/A/A",
        },
        {
          size:  "215/55R17",
          li_si: "94V",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/60R17",
          li_si: "96T",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "215/65R17",
          li_si: "99T",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/55R17",
          li_si: "101H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/60R17",
          li_si: "99H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  "225/65R17",
          li_si: "102H",
          depth: "8",
          utqg:  "420/A/A",
        },
        {
          size:  " 235/65R17",
          li_si: "104H",
          depth: "8",
          utqg:  "420/A/A",
        },
      ]
    },
    {
      name: "525",
      category: "UHP",
      description: "El compuesto de la banda que contiene silicio reduce la resistencia y mejora el agarre en carretera mojada. Diseño de bajo ruido y antideslizante.",
      image: "525/525.png",
      background: "background.png",
      slider_image:  ["525/525-detail-01.jpg", "525/525-detail-02.jpg", "525/525-detail-03.jpg" ],
      features: [
        {
         text: "DISEÑO DE BANDA<br> ASIMENTRICA",
         description: "MEJORA EL RENDIMIENTO DE LA DIRECCION EN CARRETERA  Y SEGURA ESTABILIDAD EN ALTA VELOCIDAD.",
         image: "525/525-01.png"
        },
        {
          text: 'CONTIENE<br> SILICIO',
          description: "MEJORA EL AGARRE EN CARRETERAS MOJADAS Y REDUCE LA RESISTENCIA.",
          image: "525/525-02.png"
        },
        {
          text: "DISEÑO<br> SILENCIOSO",
          description: "DISEÑO DE LINEAS FINAS QUE REDUCEN EFICAZMENTE EL RUIDO A ALTA VELOCIDAD.",
          image: "525/525-03.png"
         },
        {
          text: "ANTI-SKIP DESIGN",
          description: "PROPORCIONA UNA GRAN CAPACIDAD DE AGARRE Y CONDUCCIÓN EN CARRETERAS HUMEDAS.",
          image: "525/525-04.png"
        },
      ],
      table : [
        {
          size: "195/45R16",
          li_si: "84V XL",
          depth: "7,5",
          utqg: "400/A/A"
        },
        {
          size: "205/45R16",
          li_si: "87W XL",
          depth: "8",
          utqg: "400/A/A"
        },
        {
          size: "205/50R16",
          li_si: "91W XL",
          depth: "8",
          utqg: "400/A/A"
        },
        {
          size: "205/55R16",
          li_si: "94W XL",
          depth: "7,2",
          utqg: "400/A/A"
        },
        {
          size: "215/40ZR16",
          li_si: "86W XL",
          depth: "7,2",
          utqg: "400/A/A"
        },
        {
          size: "215/45R16",
          li_si: "90V XL",
          depth: "7,5",
          utqg: "400/A/A"
        },
        {
          size: "215/55ZR16",
          li_si: "97W XL",
          depth: "7,5",
          utqg: "400/A/A"
        },
        {
          size: "225/50ZR16",
          li_si: "96W XL",
          depth: "7,5",
          utqg: "400/A/A"
        },
        {
          size: "225/55R16",
          li_si: "99W XL",
          depth: "8",
          utqg: "400/A/A"
        },
        {
          size: "165/40R17",
          li_si: "72V",
          depth: "7,2",
          utqg: "400/A/A"
        }, 
        {
          size: "185/35R17",
          li_si: "82V",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "195/45ZR17",
          li_si: "85W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "205/40R17",
          li_si: "84W XL",
          depth: "8,2",
          utqg: "400/A/A"
        }, 
        {
          size: "205/45R17",
          li_si: "88W XL",
          depth: "8,2",
          utqg: "400/A/A"
        }, 
        {
          size: "205/50R17",
          li_si: "93W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "205/55ZR17",
          li_si: "95W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "215/40ZR17",
          li_si: "87W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "215/45R17",
          li_si: "91W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "215/50R17",
          li_si: "95W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "215/55R17",
          li_si: "98W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "225/45R17",
          li_si: "94W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "225/50R17",
          li_si: "98W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "225/55R17",
          li_si: "101W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "235/45R17",
          li_si: "97W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "235/50R17",
          li_si: "100V",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "235/55ZR17",
          li_si: "103W",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "245/40ZR17",
          li_si: "95W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "245/45ZR17",
          li_si: "99W",
          depth: "7,7",
          utqg: "400/A/A"
        },
        {
          size: "185/35R18",
          li_si: "83V",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "215/35ZR18",
          li_si: "84W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "215/40ZR18",
          li_si: "89W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "215/45ZR18",
          li_si: "93W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "215/55R18",
          li_si: "99V",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "225/40R18",
          li_si: "92W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "225/45R18",
          li_si: "95W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "225/55R18",
          li_si: "102V",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "235/40R18",
          li_si: "95W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "235/45ZR18",
          li_si: "98W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "235/50R18",
          li_si: "97V",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "235/55R18",
          li_si: "104V",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "245/40R18",
          li_si: "97W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "245/45R18",
          li_si: "100W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "245/50ZR18",
          li_si: "104W",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "255/35ZR18",
          li_si: "94W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "255/40ZR18",
          li_si: "99W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "255/45ZR18",
          li_si: "103W",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "255/55R18",
          li_si: "109V",
          depth: "7,7",
          utqg: "400/A/A"
        }, 
        {
          size: "265/35ZR18",
          li_si: "97W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "275/35ZR18",
          li_si: "99W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "215/35ZR19",
          li_si: "85W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "225/35ZR19",
          li_si: "88W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "225/45ZR19",
          li_si: "96W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "225/55R19",
          li_si: "103VXL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "235/35ZR19",
          li_si: "91W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "235/40ZR19",
          li_si: "96W",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "235/45ZR19",
          li_si: "99W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "235/50ZR19",
          li_si: "103W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "235/55R19",
          li_si: "105V XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "245/35ZR19",
          li_si: "93W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "245/40ZR19",
          li_si: "98W XL",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "245/45R19",
          li_si: "102W XL",
          depth: "8,2",
          utqg: "400/A/A"
        }, 
        {
          size: "245/55R19",
          li_si: "107V XL",
          depth: "8,2",
          utqg: "400/A/A"
        }, 
        {
          size: "255/30ZR19",
          li_si: "91Y XLL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "255/35ZR19",
          li_si: "96W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "255/40ZR19",
          li_si: "100W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "255/45ZR19",
          li_si: "104W XL",
          depth: "8",
          utqg: "400/A/A"
        },
        {
          size: "255/50R19",
          li_si: "107V XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "275/35ZR19",
          li_si: "100Y XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "275/40ZR19",
          li_si: "105W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "285/45R19",
          li_si: "111V XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "205/35R20",
          li_si: "84V",
          depth: "7,5",
          utqg: "400/A/A"
        }, 
        {
          size: "225/35ZR20",
          li_si: "90W",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "245/30ZR20",
          li_si: "90WXL",
          depth: "7,8",
          utqg: "400/A/A"
        }, 
        {
          size: "245/35ZR20",
          li_si: "95YXL",
          depth: "7,8",
          utqg: "400/A/A"
        }, 
        {
          size: "245/45ZR20",
          li_si: "103WXL",
          depth: "7,8",
          utqg: "400/A/A"
        }, 
        {
          size: "255/35ZR20",
          li_si: "97W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "255/45ZR20",
          li_si: "105WXL",
          depth: "7,8",
          utqg: "400/A/A"
        }, 
        {
          size: "275/30ZR20",
          li_si: "97YXL",
          depth: "7,8",
          utqg: "400/A/A"
        }, 
        {
          size: "275/35ZR20",
          li_si: "102WXL",
          depth: "7,8",
          utqg: "400/A/A"
        }, 
        {
          size: "315/35R20",
          li_si: "110WXL",
          depth: "7,8",
          utqg: "400/A/A"
        },
        {
          size: "265/45ZR21",
          li_si: "108W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "275/45ZR21",
          li_si: "110W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "295/35ZR21",
          li_si: "107W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
        {
          size: "295/40ZR21",
          li_si: "111W XL",
          depth: "8",
          utqg: "400/A/A"
        }, 
      ],
    },
    {
      name: "535",
      category: "Camioneta deportiva",
      description: "El diseño de patrón direccional en forma de ¨Y¨ proporciona un excelente rendimiento de drenaje horizontal o vertical. Las nervaduras longitudinales centrales aumentan la  área de contacto con la superficie de la carretera y mejora el rendimiento al momento frenar. ",
      image: "535/535.png",
      background: "background_terrain.png",
      slider_image:  ["535/535-detail-01.jpg", "535/535-detail-02.jpg", "535/535-detail-03.jpg" ],
      features: [
        {
         text: 'PATRÓN EN<br> FORMA DE "V"',
         description: "GARANTIZA UN MANEJO DE ALTA VELICIDAD Y ESTBILIDAD EN LINEAS ESTRECHAS Y CURVAS.",
         image: "535/535-01.png"
        },
        {
          text: 'DIRECCIONAL EN<br> FORMA DE "Y"',
          description: "PROPORCIONA UN EXELENTE DE RENDIMIENTO DE DRENAJE.",
          image: "535/535-02.png"
        },
        {
          text: "DISEÑO",
          description: "SU DISEÑO AUTOLIMPIA EL LODO DE LAS RANURAS.",
          image: "535/535-03.png"
         },
        {
          text: "DISEÑO DE<br> BANDA",
          description: "AUMENTA LA AREA DE CONTACTO CON LA SUPERFICIE Y MEJORANDO EL RENDIMIENTO.",
          image: "535/535-04.png"
        },
      ],
      table : [
        {
          size: "255/55R19",
          li_si: "111V",
          depth: "9",
          utqg: "400/A/A"
        },
         {
          size: "265/50R20",
          li_si: "111V",
          depth: "9",
          utqg: "400/A/A"
        },
         {
          size: "275/40ZR20",
          li_si: "106WXL",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "275/45R20",
          li_si: "110VXL",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "275/55R20",
          li_si: "117VXL",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "275/60R20",
          li_si: "119HXL",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "285/50R20",
          li_si: "116V",
          depth: "8,2",
          utqg: "400/A/A"
        },
        {
          size: "295/45ZR20",
          li_si: "114W",
          depth: "8,2",
          utqg: "400/A/A"
        },
        {
          size: "265/35ZR22",
          li_si: "102WXL",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "265/40R22",
          li_si: "106VXL",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "285/35ZR22",
          li_si: "106W",
          depth: "8,2",
          utqg: "400/A/A"
        },
        {
          size: "285/40R22",
          li_si: "110V",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "295/30ZR22",
          li_si: "103W",
          depth: "8,2",
          utqg: "400/A/A"
        },
        {
          size: "305/40R22",
          li_si: "114VXL",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "275/25ZR24",
          li_si: "96W",
          depth: "8,2",
          utqg: "400/A/A"
        },
        {
          size: "295/35ZR24",
          li_si: "110W",
          depth: "8,2",
          utqg: "400/A/A"
        },
        {
          size: "305/35ZR24",
          li_si: "112W",
          depth: "8,2",
          utqg: "400/A/A"
        },
        {
          size: "255/50R20",
          li_si: "109VXL",
          depth: "8",
          utqg: "400/A/A"
        },
        {
          size: "255/55R20",
          li_si: "110VXL",
          depth: "8",
          utqg: "400/A/A"
        },
        {
          size: "275/40ZR22",
          li_si: "107WXL",
          depth: "8",
          utqg: "400/A/A"
        },
        {
          size: "285/45R22",
          li_si: "114VXL",
          depth: "8",
          utqg: "400/A/A"
        },
      ],
    },  
    {
      name: "717",
      category: "SUV",
      description: "El diseño tiene un excelente rendimiento de dirección y manejo, reduce el ruido, mejora la capacitad de drenaje y agarre de la zona húmeda que acorta la distancia de frenado.",
      image: "717/717.png",
      background: "background_terrain.png",
      slider_image:  ["717/717-detail-01.jpg", "717/717-detail-02.jpg", "717/717-detail-03.jpg" ],
      features: [
        {
         text: "DISEÑO",
         description: "DISEÑO DE BANDA PARA TODAS LAS ESTACIONES.",
         image: "717/717-01.png"
        },
        {
          text: "BANDA<br> COORDINADA",
          description: "ASEGURAN EXCELENTE RENDIMIENTO DE DIRECCIÓN Y MANEJO.",
          image: "717/717-02.png"
        },
        {
         text: 'DISEÑO<br> SILENCIOSO',
          description: "5 TONOS CON LA MEJOR PERMUTACIÓN QUE REDUCE EFICAZMENTE EL RUIDO.",
          image: "717/717-03.png"
        },
        {
          text: "HIGH DENSITY<br> GROOVES",
          description: "DISEÑO DE RANURAS DE ALTA DENSIDAD QUE AUMENTA EL CONTACTO CON LA SUPERFICIE Y MEJORA EL AGARRE.",
          image: "717/717-04.png"
        },
      ],
      table : [
        {
          size: "215/75R15",
          li_si: "100H",
          depth: "8,7",
          utqg: "460/A/A"
        },
        {
          size: "225/75R15",
          li_si: "102H",
          depth: "8,7",
          utqg: "460/A/A"
        },
        {
          size: "235/75R15",
          li_si: "105H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "265/70R15",
          li_si: "112T",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "215/65R16",
          li_si: "102H",
          depth: "8,7",
          utqg: "460/A/A"
        },
        {
          size: "215/70R16",
          li_si: "100H",
          depth: "8,7",
          utqg: "460/A/A"
        },
        {
          size: "225/70R16",
          li_si: "103H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "225/75R16",
          li_si: "104T",
          depth: "8,7",
          utqg: "460/A/A"
        },
        {
          size: "235/60R16",
          li_si: "100H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "235/70R16",
          li_si: "106H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "245/70R16",
          li_si: "111H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "255/70R16",
          li_si: "111H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "265/70R16",
          li_si: "112T",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "265/75R16",
          li_si: "116T",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "215/60R17",
          li_si: "100H",
          depth: "8,7",
          utqg: "460/A/A"
        },
        {
          size: "215/65R17",
          li_si: "99V",
          depth: "8,7",
          utqg: "460/A/A"
        },
        {
          size: "235/60R17",
          li_si: "102H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "235/65R17",
          li_si: "108H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "235/70R17",
          li_si: "107H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "245/65R17",
          li_si: "111H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "265/65R17",
          li_si: "112H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "265/70R17",
          li_si: "115H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "235/60R18",
          li_si: "107H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "245/60R18",
          li_si: "105H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "255/60R18",
          li_si: "112H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "265/60R18",
          li_si: "114H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "275/65R18",
          li_si: "116H",
          depth: "9,3",
          utqg: "460/A/A"
        },
        {
          size: "255/55R19",
          li_si: "111V",
          depth: "9,3",
          utqg: "460/A/A"
        },
      ],
    },  
    {
      name: "727",
      category: "All terrain",
      description: "Diseño de neumático todo terreno, diseño de la banda más resistente, diseño de expulsión de piedras, hoja de acero 3D que un agarre eficaz en condiciones húmedas mejorando la tracción en la conducción.	La fórmula especial del pegamento de la banda de rodadura proporciona una vida útil más larga.",
      image: "727/727.png",
      background: "background.png",
      slider_image:  ["727/727-detail-01.jpg", "727/727-detail-02.jpg", "727/727-detail-03.jpg" ],
      features: [
        {
         text: "DISEÑO TODO<br> TERRENO",
         description: "DISEÑO ROBUSTO DEL BLOQUE DE LA BANDA DE RODADURA.",
         image: "727/727-01.png"
        },
        {
          text: 'DISEÑO DE<br> EXPULSIÓN DE PIEDRAS',
          description: "PREVIENE LA RETENCIÓN  Y FORTALECE EL RENDIMIENTO.",
          image: "727/727-02.png"
        },
        {
          text: "LAMINAS DE<br> ACERO EN ZIGZAG",
          description: "MEJORA LA CONDUCCIÓN DE TRACCIÓN Y MEJORA EL AGARRE EN CARRETERAS MOJADAS.",
          image: "727/727-03.png"
         },
        {
          text: "RESISTENCIA",
          description: "FORMULA ESPECIAL DE PEGAMENTO PARA LA BANDA DE RODADURA VIDA MAS LARGA.",
          image: "727/727-04.png"
        },
      ],
      table : [
        {
          size: "235/75R15",
          li_si: "109S XL",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "235/70R16",
          li_si: "106T",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "245/70R16",
          li_si: "107T",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "265/70R16",
          li_si: "112T",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "235/65R17",
          li_si: "104T",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "245/65R17",
          li_si: "107S",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "265/70R17",
          li_si: "115S",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "31X10.50R15LT",
          li_si: "109S",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT225/75R16",
          li_si: "115/112Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT235/85R16",
          li_si: "120/116Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT245/75R16",
          li_si: "120/116Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT265/70R17",
          li_si: "121/118R",
          depth: "12,5",
          utqg: ""
        },
      ],
    },  
    {
      name: "737",
      category: "All Terrain",
      description: "Diseño de neumático todo terreno, diseño de la banda más resistente, diseño de expulsión de piedras, hoja de acero 3D que un agarre eficaz en condiciones húmedas mejorando la tracción en la conducción.	La fórmula especial del pegamento de la banda de rodadura proporciona una vida útil más larga.",
      image: "737/737.png",
      background: "background.png",
      slider_image:  ["737/737-detail-01.jpg", "737/737-detail-02.jpg", "737/737-detail-03.jpg" ],
      features: [
        {
         text: "DISEÑO TODO<br> TERRENO",
         description: "PATRON DE NEUMÁTICOS TODO TERRENO DE CUATRO ESTACIONES.",
         image: "737/737-01.png"
        },
        {
          text: 'DISEÑO DE<br> EXPULSIÓN DE PIEDRAS',
          description: "PREVIENE LA RETENCIÓN  Y FORTALECE EL RENDIMIENTO.",
          image: "737/737-02.png"
        },
        {
          text: "LAMINAS DE<br> ACERO EN ZIGZAG",
          description: "MEJORA LA CONDUCCIÓN DE TRACCIÓN Y MEJORA EL AGARRE EN CARRETERAS MOJADAS.",
          image: "737/737-03.png"
         },
        {
          text: "RESISTENCIA",
          description: "FORMULA ESPECIAL DE PEGAMENTO PARA LA BANDA DE RODADURA VIDA MAS LARGA.",
          image: "737/737-04.png"
        },
      ],
      table : [
        {
          size: "215/65R16",
          li_si: "98T",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "225/70R16",
          li_si: "103T",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "245/75R16",
          li_si: "111T",
          depth: "8,8",
          utqg: "400/A/A"
        },
        {
          size: "255/70R16",
          li_si: "111T",
          depth: "8,8",
          utqg: "400/A/A"
        },
        {
          size: "265/70R16",
          li_si: "112T",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "215/65R17",
          li_si: "99T",
          depth: "9",
          utqg: "400/A/A"
        },
        {
          size: "225/60R17",
          li_si: "99H",
          depth: "8,8",
          utqg: "400/A/A"
        },
        {
          size: "265/65R17",
          li_si: "112T",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "265/70R17",
          li_si: "115S",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "275/65R17",
          li_si: "115T",
          depth: "8,8",
          utqg: "400/A/A"
        },
        {
          size: "285/65R17",
          li_si: "116T",
          depth: "8,8",
          utqg: "400/A/A"
        },
        {
          size: "255/60R18",
          li_si: "112T",
          depth: "10",
          utqg: "400/A/A"
        },
        {
          size: "265/60R18",
          li_si: "110T",
          depth: "10",
          utqg: "400/A/A"
        },
        {
          size: "275/65R18",
          li_si: "116T",
          depth: "10",
          utqg: "400/A/A"
        },
        {
          size: "285/60R18",
          li_si: "120S XL",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "305/60R18",
          li_si: "120S",
          depth: "10",
          utqg: "460/A/A"
        },
        {
          size: "265/50R20",
          li_si: "111S XL",
          depth: "10",
          utqg: "400/A/A"
        },
        {
          size: "275/55R20",
          li_si: "117S XL",
          depth: "10",
          utqg: "400/A/A"
        },
        {
          size: "285/55R20",
          li_si: "119S XL",
          depth: "10",
          utqg: "400/A/A"
        },
        {
          size: "305/50R20",
          li_si: "120S XL",
          depth: "10",
          utqg: "400/A/A"
        },
        {
          size: "205R16C",
          li_si: "110/108S",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "LT215/75R15",
          li_si: "100/97Q",
          depth: "11",
          utqg: ""
        },
        {
          size: "LT235/75R15",
          li_si: "104/101S",
          depth: "11",
          utqg: ""
        },
        {
          size: "LT245/70R16",
          li_si: "113/110S",
          depth: "11",
          utqg: ""
        },
        {
          size: "LT265/70R16",
          li_si: "121/118S",
          depth: "11,5",
          utqg: ""
        },
        {
          size: "LT265/75R16",
          li_si: "123/120Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT285/75R16",
          li_si: "126/123Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT245/70R17",
          li_si: "119/116S",
          depth: "11",
          utqg: ""
        },
        {
          size: "LT245/75R17",
          li_si: "121/118S",
          depth: "11",
          utqg: ""
        },
        {
          size: "LT285/70R17",
          li_si: "121/118Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT285/60R18",
          li_si: "122/119Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT285/65R18",
          li_si: "125/122R",
          depth: "11,5",
          utqg: ""
        },
        {
          size: "LT325/60R18",
          li_si: "124/121Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT325/65R18",
          li_si: "127/124Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "31X10.50R15LT",
          li_si: "109S",
          depth: "11",
          utqg: ""
        },
      ],
    },  
    /* {
      name: "757",
      category: "All Terrain",
      description: "Patrón con forma de pirámide que mejora el agarre en carreteras, surcos de auto limpieza que garantizan con condición segura y suave, diseño de bloque que protege en gran medida de lesiones externas..",
      image: "757/757.jpg",
      background: "background.png",
      slider_image:  [],
      features: [
        {
         text: "PATRON EN<br> FORMA DE PIRAMIDE",
         description: "MEJORA EL AGARRE Y TRACCIÓN EN CARRETERAS DE ARENA.",
         image: "757/757-01.png"
        },
        {
          text: 'RANURAS<br> AUTOLIMPIADORAS',
          description: "RECHAZO DE AGUA NIEVE QUE GARANTIZA UNA CONDUCCIÓN SEGURA Y SIN PROBLEMAS.",
          image: "757/757-02.png"
        },
        {
          text: "DISEÑO",
          description: "EL DISEÑO PROTEGE ENORMEMETE EL NEUMATICO DE LESIONES.",
          image: "757/757-03.png"
         },
        {
          text: "ANTI-SKIP-DESIGN",
          description: "PROPORCIONA UNA GRAN CAPACIDAD DE AGARRE Y CONDUCCIÓN EN CARRETERAS HUMEDAS.",
          image: "757/757-04.png"
        },
      ],
      table : [
        {
          size: "31x10.50R15LT",
          li_si: "109Q",
          depth: "14",
          utqg: ""
        },
        {
          size: "32X11.50R15LT",
          li_si: "113Q",
          depth: "15,5",
          utqg: ""
        },
        {
          size: "33X12.50R15LT",
          li_si: "108Q",
          depth: "15",
          utqg: ""
        },
        {
          size: "33X12.50R17LT",
          li_si: "120Q",
          depth: "15,5",
          utqg: ""
        },
        {
          size: "LT215/75R15",
          li_si: "100/97Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT235/75R15",
          li_si: "104/101Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT215/85R16",
          li_si: "115/112Q",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT225/75R16",
          li_si: "115/112N",
          depth: "12,5",
          utqg: ""
        },
        {
          size: "LT235/85R16",
          li_si: "120/116Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT245/75R16",
          li_si: "120/116N",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT305/70R16",
          li_si: "124/121P",
          depth: "14,5",
          utqg: ""
        },
        {
          size: "LT315/75R16",
          li_si: "127/124Q",
          depth: "14,5",
          utqg: ""
        },
      ],
    },   */
    {
      name: "767",
      category: "4X4",
      description: "El mejor agarre en terrenos fuera de carretera, rendimiento de auto limpieza, expulsión de piedras, disipación del calor y agarre adecuado para todo tipo de condiciones complejas.",
      image: "767/767.png",
      background: "background.png",
      slider_image:  ["767/767-detail-01.jpg", "767/767-detail-02.jpg", "767/767-detail-03.jpg" ],
      features: [
        {
         text: "DISEÑO DE<br> 'TUBURÓN'",
         description: "COMPLEJO DE TODO TERRENO QUE MEJORA EL AGARRE DE LOSNEUMÁTICOS.",
        
        },
        {
          text: 'HIGH DENSITY<br> GROOVES',
          description: "DISEÑO DE RANURAS DE ALTA DENSIDAD QUE AUMENTA EL CONTACTO CON LA SUPERFICIE Y MEJORA EL AGARRE.",
        },
        {
          text: "RANURAS<br> AUTOLIMPIADORAS",
          description: "RECHAZO DE AGUA NIEVE QUE GARANTIZA UNA CONDUCCIÓN SEGURA Y SIN PROBLEMAS.",
         },
        {
          text: "DISEÑO LATERAL<br> BIÓNICO",
          description: "MEJORA EL IMPACTO Y LA RESISTENCIA",
        },
      ],
      table : [
        {
          size: "LT235/85R16",
          li_si: "120/116Q",
          depth: "13",
          utqg: ""
        },
        {
          size: "LT235/75R15",
          li_si: "104/101Q",
          depth: "13",
          utqg: ""
        },
        {
          size: "LT235/70R16",
          li_si: "110/107Q",
          depth: "13",
          utqg: ""
        },
        {
          size: "LT265/75R16",
          li_si: "119/116Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT265/75R16",
          li_si: "123/120Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT265/70R17",
          li_si: "121/118Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT285/75R16",
          li_si: "116/113Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT285/75R16",
          li_si: "126/123Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT285/70R17",
          li_si: "121/118Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "LT245/70R16",
          li_si: "118/115Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "31X10.50R15LT",
          li_si: "109Q",
          depth: "13,5",
          utqg: ""
        },
        {
          size: "35X12.50R15LT",
          li_si: "113Q",
          depth: "14",
          utqg: ""
        },
        {
          size: "35X12.50R17LT",
          li_si: "121Q",
          depth: "14",
          utqg: ""
        }, 
        {
          size: "33X12.50R18LT",
          li_si: "118Q",
          depth: "14",
          utqg: ""
        },  
        {
          size: "33X12.50R20LT",
          li_si: "114Q",
          depth: "14",
          utqg: ""
        }, 
        {
          size: "35X12.50R18LT",
          li_si: "118Q",
          depth: "14",
          utqg: ""
        },  
        {
          size: "35X12.50R20LT",
          li_si: "121Q",
          depth: "14",
          utqg: ""
        },  
      ],
    },
    {
      name: "838",
      category: "All Terrain",
      description: "Diseño silencioso de chapas de acero que mejora el confort de conducción, acorta la distancia del frenado, mejora la seguridad y el rendimiento de agarre sin reducir la rigidez.",
      image: "838/838.png",
      background: "background.png",
      slider_image:  ["838/838-detail-01.jpg", "838/838-detail-02.jpg", "838/838-detail-03.jpg" ],
      features: [
        {
         text: "DISEÑO<br> SILENCIOSO ",
         description: "DISEÑO DE LINEAS FINAS QUE REDUCEN EFICAZMENTE EL RUIDO A ALTA VELOCIDAD.",
         image: "838/838-01.png"
        },
        {
          text: 'DIRECCIONAL EN<br> FORMA DE "Y"',
          description: "PROPORCIONA UN EXELENTE DE RENDIMIENTO DE DRENAJE.",
          image: "838/838-02.png"
        },
        {
          text: "LAMINAS DE<br> ACERO EN ZIGZAG",
          description: "MEJORA LA CONDUCCIÓN DE TRACCIÓN Y MEJORA EL AGARRE EN CARRETERAS MOJADAS.",
          image: "838/838-03.png"
         },
        {
          text: "DISEÑO LATERAL<br> BLANCO",
          description: "DISEÑO ESTETICO QUE  MEJORA LA RESISTENCIA ",
          image: "838/838-04.png"
        },
      ],
      table : [
        {
          size: "185R14C",
          li_si: "102/100R",
          depth: "9",
          utqg: ""
        },
        {
          size: "185R14C",
          li_si: "102/100R",
          depth: "9",
          utqg: ""
        },
        {
          size: "185R14C",
          li_si: "102/100R",
          depth: "9",
          utqg: ""
        },
        {
          size: "195R14C",
          li_si: "106/104R",
          depth: "9",
          utqg: ""
        },
        {
          size: "195R14C",
          li_si: "106/104R",
          depth: "9",
          utqg: ""
        },
        {
          size: "205R14C",
          li_si: "109/107R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "215R14C",
          li_si: "112/110R",
          depth: "9",
          utqg: ""
        },
        {
          size: "195R15C",
          li_si: "106/104R",
          depth: "9",
          utqg: ""
        },
        {
          size: "195R15C",
          li_si: "106/104R",
          depth: "9",
          utqg: ""
        },
        {
          size: "165/70R14",
          li_si: "89/87R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "175/65R14C",
          li_si: "90/88R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "195/70R15C",
          li_si: "104/102R",
          depth: "9",
          utqg: ""
        },
        {
          size: "205/70R15C",
          li_si: "106/104R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "215/65R15C",
          li_si: "104/102R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "215/70R15C",
          li_si: "104/101R",
          depth: "9",
          utqg: ""
        },
        {
          size: "225/70R15C",
          li_si: "112/110R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "175/75R16C",
          li_si: "101/99R",
          depth: "9",
          utqg: ""
        },
        {
          size: "185/75R16C",
          li_si: "104/102R",
          depth: "8",
          utqg: ""
        },
        {
          size: "195/60R16C",
          li_si: "99/97T",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "195/65R16C",
          li_si: "104/102R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "195/75R16C",
          li_si: "107/105R",
          depth: "9",
          utqg: ""
        },
        {
          size: "205/65R16C",
          li_si: "107/105R",
          depth: "9",
          utqg: ""
        },
        {
          size: "205/75R16C",
          li_si: "110/108R",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "215/60R16C",
          li_si: "103/101T",
          depth: "8,5",
          utqg: ""
        },
        {
          size: "215/65R16C",
          li_si: "109/107R",
          depth: "9",
          utqg: ""
        },
        {
          size: "225/65R16C",
          li_si: "112/110R",
          depth: "9",
          utqg: ""
        },
        {
          size: "235/65R16C",
          li_si: "115/113R",
          depth: "9",
          utqg: ""
        },
      ],
    },  
  ]

  constructor(
  ) { }

  public getDataLlantas(){
    return this.data_llantas;
  }
  


}
