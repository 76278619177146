<div class="container align">
    <h4 class="titulo">Nosotros</h4>

    <div class="text">
      
        <p><img class="img-fluid" src="assets/images/nosotros/image.jpg" alt=""> Rockblade Tires forma parte del grupo Zhengdao Tire Co., Ltd. Ubicado en el condado de Cao, Shandong desde agosto de 2011.<br><br>
    
        El alcance comercial es el desarrollo, producción, ventas y servicios técnicos de neumáticos y productos de caucho. En la actualidad, la compañía emplea a más de 2,000 personas.<br><br>
    
        La inversion total del proyecto es de 3.500 millones de yuanes y es una empresa líder en el Parque Industrial Caoxian Rubber.<br><br>
    
        El proyecto utiliza la tecnología Goodyear de los Estados Unidos e importó caucho natural y otras materias primas de Indonesia y Malasia para implementar mas de 500 procedimientos para producir mas de 1,000 llantas radiales de semi-acero de alto rendimiento.<br><br>
    
        La compañía cuenta con un equipo de personal técnico y de gestión de primera clase, estableció y mejoró el sistema interno de gestión de calidad y la red de ventas, tiene equipos de producción avanzados y equipos de prueba en el hogar y en el extranjero, los equipos de producción y equipos de prueba clave se importan de Japón, Alemania, Estados Unidos y otros países.</p>
    </div>
</div>
<!-- <section class="section-charts">
    <div class="container">
      <div class="row" id="scrollEvent">
          <div class="col-4 text-center"  data-aos="fade-up" data-aos-duration="1500">
            <h3  class="value">53</h3>
            <p>Países</p>
          </div>
          <div class="col-4 text-center"  data-aos="fade-up" data-aos-duration="2000">
            <h3  class="value">77</h3>
            <p>Años</p>
          </div>
          <div class="col-4 text-center"  data-aos="fade-up" data-aos-duration="2500">
            <h3  class="value">87</h3>
            <p>Distribuidores</p>
          </div>
      </div>
    </div>
</section> -->
<section class="py-5 bg-gray" id="certificaciones">
    <div class="container">
        <h2 class="title-section text-center mb-5 gray-title" data-aos="fade-up" data-aos-duration="1000">
            Reconocimientos y certificados
        </h2>
        <owl-carousel-o [options]="customOptions" class="custom-slider">
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-01.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-02.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-03.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-04.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-05.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-06.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-07.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-08.png" alt="">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img class="img-fluid" src="assets/images/nosotros/certi-09.png" alt="">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>