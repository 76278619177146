<div id="slider" class="carousel slide slider-home carousel-fade" data-bs-ride="carousel" data-bs-interval="false">
    <div class="carousel-indicators">
      <button type="button" [ngClass]="{'active': slider_default == 1}" (mouseover)="sliderChange(1)" data-bs-target="#slider" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
          Automovil
        <div class="transition-hover">
          Automovil
        </div>
      </button>
      <button type="button" [ngClass]="{'active': slider_default == 2}" (mouseover)="sliderChange(2)" data-bs-target="#slider" data-bs-slide-to="1" aria-label="Slide 2">
        UHP
      <div class="transition-hover">
        UHP
      </div>
      </button>
      <button type="button" [ngClass]="{'active': slider_default == 3}" (mouseover)="sliderChange(3)" data-bs-target="#slider" data-bs-slide-to="2" aria-label="Slide 3">
          Camioneta deportiva
        <div class="transition-hover">
          Camioneta deportiva
        </div>
      </button>
      <button type="button" [ngClass]="{'active': slider_default == 4}" (mouseover)="sliderChange(4)" data-bs-target="#slider" data-bs-slide-to="3" aria-label="Slide 4">
          4X4
        <div class="transition-hover">
          4X4
        </div>
      </button>
    </div>
    <div class="carousel-inner">
      <div class="boxImages first-image" *ngIf="slider_default == 1" [@enterAnimation]>
        <img src="assets/images/home/slider/515.png" alt="" class="img-fluid llanta">
      </div>
      <div class="boxImages second-image" *ngIf="slider_default == 2" [@enterAnimation]>
        <img src="assets/images/home/slider/525.png" alt="" class="img-fluid llanta">
      </div>
      <div class="boxImages third-image" *ngIf="slider_default == 3" [@enterAnimation]>
        <img src="assets/images/home/slider/535.png" alt="" class="img-fluid llanta">
      </div>
      <div class="boxImages four-image" *ngIf="slider_default == 4" [@enterAnimation]>
        <img src="assets/images/home/slider/767.png" alt="" class="img-fluid llanta">
      </div>
    </div>
  </div>