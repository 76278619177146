import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NosotrosComponent } from './views/nosotros/nosotros.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactoComponent } from './views/contacto/contacto.component';
import { LlantasComponent } from './views/llantas/llantas.component';
import { SucursalesComponent } from './views/sucursales/sucursales.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxCaptchaModule } from 'ngx-captcha'

import { NgxMaskModule, IConfig } from 'ngx-mask'


//enviroment
import { environment } from "../environments/environment";
import { AvisoPrivacidadComponent } from './views/aviso-privacidad/aviso-privacidad.component'
import * as $ from 'jquery';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    NosotrosComponent,
    ContactoComponent,
    LlantasComponent,
    SucursalesComponent,
    AvisoPrivacidadComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.api_key,
      libraries: ['places']
    }),
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    NgxImageZoomModule ,
    NgxCaptchaModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
