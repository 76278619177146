import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { NosotrosComponent } from './views/nosotros/nosotros.component';
import { ContactoComponent } from './views/contacto/contacto.component';
import { LlantasComponent } from './views/llantas/llantas.component';
import { SucursalesComponent } from './views/sucursales/sucursales.component';
import { AvisoPrivacidadComponent } from './views/aviso-privacidad/aviso-privacidad.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'nosotros',
    component: NosotrosComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: 'llantas',
    component: LlantasComponent
  },
  {
    path: 'llantas/:category',
    component: LlantasComponent
  },
  {
    path: 'distribuidores',
    component: SucursalesComponent
  },
  {
    path: 'aviso-privacidad',
    component: AvisoPrivacidadComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
