import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {

    customOptions: any = {
    loop: false,
    margin: 10,
    autoplay:true,
    responsiveClass: true,
    dots: false,
    navText: ["<img src='assets/images/nosotros/arrow-left.png'>", "<img src='assets/images/nosotros/arrow-right.png'>"],
    responsive: {
      0: {
       items: 2,
       nav: false,
       dots: true

     },
      480: {
       items: 3
     },
      940: {
       items: 4
     }
    },
   nav: true
  }

  constructor() { }

  ngOnInit(): void {
  }

}
