import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import axios from 'axios';
import {environment} from "src/environments/environment"

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  formContact: FormGroup;
  siteKey: string;
  submitted = false;

  constructor(
    private fb                : FormBuilder,
  ) { 
    this.siteKey = "6Ld_uYAaAAAAAMQ1U8XnyJ622AXJAPz-dMjJY4-y";

    this.formContact = this.fb.group({
      name           :       ['', Validators.compose([Validators.required,  Validators.minLength(4)])],
      phone          :       ['', Validators.compose([Validators.required,  Validators.minLength(10)])],
      email          :       ['', Validators.compose([Validators.email, Validators.required,  Validators.minLength(4)])],
      comment        :       ['', Validators.compose([Validators.required,  Validators.minLength(8)])],
      recaptcha      :       ['', Validators.required]
    })
  }

  ngOnInit(): void {
  }

  
  async onSubmit() {
    this.submitted = true;
    console.log(this.formContact.controls.recaptcha.errors)
    if (this.formContact.valid) {
      let dataPost = {
        name: this.formContact.value.name,
        phone: this.formContact.value.phone,
        email: this.formContact.value.email,
        comment: this.formContact.value.comment,
      }
      console.log('Data Send ===>', dataPost)
      /* axios.post(``, dataPost, {
        headers: {
          "X-Authentication": environment.smToken,
          "FA-UserToken": environment.userToken 
        }
        })
        .then( response => {
          // Obtenemos los datos
          console.log(response)

        })
        .catch(e => {
          console.log(e)
            // Capturamos los errores

        }) */
    }
  }

  

}
