<section class="bg-contacto" id="contacto">
    <div class="contacto style">
      <form class="float-label"  [formGroup]="formContact" (ngSubmit)="onSubmit()">
        <div class="row mx-0">
          <div class="col-12">
            <h3 class="text-center title">Contacto</h3>
            <p class="text-center">Comunicate con nosotros para cualquier duda.</p>
          </div>
          <div class="col-md-6 col-sm-6 col-12">
            <div class="form-group">
              <input class="form-control" formControlName="name" type="text" id="name" placeholder="Nombre" required>
            </div>
            <div *ngIf="formContact.controls.name.invalid && (formContact.controls.name.dirty || formContact.controls.name.touched || submitted)"
                class="custom-alert">
              <div *ngIf="formContact.controls.name.errors?.required">
                Nombre es requerido.
              </div>
              <div *ngIf="formContact.controls.name.errors?.minlength">
                El nombre debe tener mínimo 4 caracteres.
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-12">
            <div class="form-group">
              <input class="form-control" formControlName="phone" type="text" id="telephono" placeholder="Teléfono" required mask="(000) 000-0000">
            </div>
             <div *ngIf="formContact.controls.phone.invalid && (formContact.controls.phone.dirty || formContact.controls.phone.touched || submitted)"
                class="custom-alert">
              <div *ngIf="formContact.controls.phone.errors?.required">
                Este campo es requerido.
              </div>
              <div *ngIf="formContact.controls.phone.errors?.minlength">
                El telefono debe tener mínimo 10 caracteres.
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-12">
            <div class="form-group">
              <input class="form-control" formControlName="email" type="email" id="email" placeholder="Correo" required>
            </div>
             <div *ngIf="formContact.controls.email.invalid && (formContact.controls.email.dirty || formContact.controls.email.touched) || submitted"
                class="custom-alert">
              <div *ngIf="formContact.controls.email.errors?.required">
                Este campo es requerido.
              </div>
              <div *ngIf="formContact.controls.email.errors?.email">
                Escriba un correo electrónico válido.
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <textarea class="form-control" formControlName="comment" type="text" id="comment" placeholder="Comentarios" required></textarea>
            </div>
             <div *ngIf="formContact.controls.comment.invalid && (formContact.controls.comment.dirty || formContact.controls.comment.touched) || submitted"
                class="custom-alert">
              <div *ngIf="formContact.controls.comment.errors?.required">
                Este campo es requerido.
              </div>
              <div *ngIf="formContact.controls.comment.errors?.minlength">
                El comentario debe tener mínimo 8 caracteres.
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-12">
            <!-- Captcha -->
            <div class="form-group">
              <ngx-recaptcha2 #captchaElem
              [siteKey]="siteKey"
              theme="light"
              formControlName="recaptcha">
            </ngx-recaptcha2>
            </div>
            <div *ngIf="formContact.controls.recaptcha.invalid && (formContact.controls.recaptcha.dirty || formContact.controls.recaptcha.touched) || submitted"
                class="custom-alert">
              <div *ngIf="formContact.controls.recaptcha.errors?.required">
                Este campo es requerido.
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-12">
            <button class="btn btn-primary btn-send" type="submit" (click)="onSubmit()">Enviar</button>
          </div>
        </div>
      </form>
    </div>
</section>