<div class="container align">
    <h4 class="titulo">Distribuidores</h4>
    <div class="form-group">
        <div class="input-group mb-3 SearchEdit">
            <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" (ngModelChange)="modelChanged($event)" [(ngModel)]="text_search">
            <div class="input-group-append">
              <button class="btn" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                  </svg>
              </button>
            </div>
        </div>
    </div>
    <div class="body_cards" >
        <div class="row">
            <div class="col-lg-6 col-12" *ngFor="let item of data">
                <div class="card">
                    <div class="image">
                        <img class="img-fluid" [src]="url_image + item.image" alt="">
                    </div>
                    <div class="text">
                        <div class="body">
                            <h5 class="title">Econollantas</h5>
                            <h5>{{item.name}}</h5>
                            <p>{{item.street}}, {{item.city.name}}, {{item.city.state.name}}</p>
                            <p>Lunes-Viernes 08:00:00 - 18:30:00</p>
                            <p>Sábado  08:00:00 - 15:00:00</p>
                            <p>{{item.phone}}</p>
                            <a href="mailto:{{item.email}}">{{item.email}}</a>
                        </div>
                        <button class="btn" data-bs-toggle="modal" data-bs-target="#modal_ubicación" (click)="openModalDetails(item)">Ver ubicación</button>
                    </div>
                </div>
            </div>
            <div class="spinner-border text-center" role="status" *ngIf="loading">
            </div>
            <div class="col-12" *ngIf="data?.length == 0">
                <h5 class="nothing_text">No hay sucursales relacionados.</h5>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modal_ubicación" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modalEdit">
        <div class="modal-content">
            <div class="modal-header p-0">
                <div class="row mx-0 w-100">
                    <div class="col-sm-6 col-12 p-0">
                        <img class="img-fluid w-100 img-sucursal" [src]="url_image + data_modal?.image" alt="">
                    </div>
                    <div class="col-sm-6 cosl-12">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="text">
                            <h5>{{data_modal?.name}}</h5>
                            <p>{{data_modal?.street}}, {{data_modal?.city.name}}, {{data_modal?.city.state.name}}</p>
                            <p>Lunes-Viernes 08:00:00 - 18:30:00</p>
                            <p>Sábado  08:00:00 - 15:00:00</p>
                            <p>{{data_modal?.phone}}</p>
                            <a href="mailto:{{data_modal?.email}}">{{data_modal?.email}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body p-0">
                <agm-map [latitude]="geo_lat" [longitude]="geo_lng">
                    <agm-marker [latitude]="geo_lat" [longitude]="geo_lng"></agm-marker>
                </agm-map>
            </div>
        </div>
    </div>
</div>