import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../services/global';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-llantas',
  templateUrl: './llantas.component.html',
  styleUrls: ['./llantas.component.scss']
})
export class LlantasComponent implements OnInit {
  data = this._global.getDataLlantas();
  data_modal: any;
  active_filter: any;
  carousel: boolean = false;

  customOptions: any = {
    loop: false,
    margin: 10,
    autoplay:false,
    responsiveClass: true,
    dots: false,
    navText: ["<img src='assets/images/nosotros/arrow-left.png'>", "<img src='assets/images/nosotros/arrow-right.png'>"],
    responsive: {
      0: {
       items: 1,
       nav: false,
       dots: true
     },
      480: {
       items: 2
     },
      940: {
       items: 2
     }
    },
   nav: true
  }

  constructor(
      private _global                 : GlobalService,
      private _router                 : Router,
      private activatedRoute          : ActivatedRoute,

  ) {
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.category){
        console.log(params); // Print the parameter to the console. 
        this.select(params.category)
      }
  });
  }

  select(params: string){
    let dataCurrent = this._global.getDataLlantas();
    let filter_Date = dataCurrent.filter(item => {
      return item.category.toLocaleLowerCase().includes(params.toLocaleLowerCase())
    })
    this.data = filter_Date;
  }

  // Multiple category
 /*  select(type: string){
    let newDate: any = [];
    let dataCurrent = this._global.getDataLlantas();
     dataCurrent.filter(item => {
       item.category.map((n) => {
          if (n == type.toLocaleLowerCase()){
            newDate.push(item);
          } 
        })
    })
    this.data = newDate;
    console.log(newDate)
  } */

  openModalDetails(item: any){
    this.carousel = false;
    this.data_modal = item;
    setTimeout(() => {
      this.carousel = true;
    }, 200);
  }
}
