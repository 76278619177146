<div class="container align">
    <h4 class="titulo">Conoce nuestras llantas</h4>
    <div class="body_cards" >
        <div class="card" *ngFor="let item of data" style="background-image: url('assets/images/llantas/{{item.background}}')">
            <img class="img-fluid" src="assets/images/llantas/{{item.image}}" alt="">
            <div class="text-card">
                <div class="row align-items-center">
                    <div class="col-4 col-sm-4 col-md-6">
                        <h5>{{item.name}}</h5>
                    </div>
                    <div class="col-8 col-sm-8 col-md-6">
                        <div class="box" [ngClass]="{'text-large': item.category === 'Camioneta deportiva'}" >
                            <span>{{item.category}}</span>
                        </div>
                    </div>
                    <div class="col-12 border-edit">
                        <p>{{item.description}}</p>
                        <button class="btn btn-details" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="openModalDetails(item)">
                            +Detalles
                        </button>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modalEdit">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{data_modal?.category}}  <span>{{data_modal?.name}}</span></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <owl-carousel-o [options]="customOptions" class="carousel"  *ngIf="carousel">
                    <ng-template carouselSlide  *ngFor="let img of data_modal?.slider_image">
                        <div class="slide">
                            <lib-ngx-image-zoom
                                thumbImage="assets/images/llantas/{{img}}"
                                fullImage="assets/images/llantas/{{img}}"
                                [magnification]="0.85"
                                [enableScrollZoom]="true"
                                [enableLens]="true"
                                [lensWidth]="175"
                            ></lib-ngx-image-zoom>
                        </div>
                    </ng-template>
                </owl-carousel-o>
                <div class="row caract" *ngIf="data_modal?.name != '767'">
                    <div class="col-md-3 col-6" *ngFor="let item of data_modal?.features">
                       <div class="form-group">
                           <h5 [innerHTML]="item.text"></h5>
                           <img class="img-fluid" src="assets/images/llantas/{{item.image}}" alt="">
                           <p>{{item.description}}</p>
                       </div> 
                    </div>
                </div>
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Size</th>
                        <th scope="col">LI/SI</th>
                        <th scope="col">Depth (mm)</th>
                        <th scope="col">UTQG</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let table of data_modal?.table">
                        <td>{{table.size}}</td>
                        <td>{{table.li_si}}</td>
                        <td>{{table.depth}}</td>
                        <td>{{table.utqg}}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
    </div>
</div>