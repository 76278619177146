import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import {environment} from "src/environments/environment"

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.scss']
})
export class SucursalesComponent implements OnInit {
  data: any;
  data_modal: any;
  current_data: any;
  url_image = environment.img_url;
  page = 1;
  items = 10;
  text_search: string = '';
  loading: boolean = false;

  geo_lat: any;
  geo_lng: any;


  constructor() { }

  ngOnInit(): void {
    this.getData()
  }

 getData(){
   this.loading = true;
   return new Promise((resolve, reject)  => {
     
       axios.get(`${environment.api_url}?page=${this.page}&items=${this.items}`, {
        headers: {
          "X-Authentication": environment.smToken,
          "FA-UserToken": environment.userToken 
        }
        })
        .then( response => {
          // Obtenemos los datos
          if (response['status'] == 200){
            this.data = response.data.all;
            this.current_data = response.data.all;
            console.log(this.data)
           this.loading = false;
          }
          resolve(response)
        })
        .catch(e => {
            // Capturamos los errores
            reject(e);
            this.loading = false;
        })
      })
    }


    modelChanged(newObj: any) {
      console.log(newObj);
       let filter_data = this.current_data.filter((item: any) =>{
          return item.name.toLowerCase().includes(newObj.toLowerCase())
      })

      this.data = filter_data;
    }

    openModalDetails(data: any){
      this.data_modal = data;
      this.geo_lat = parseFloat(data.geo_lat);
      this.geo_lng = parseFloat(data.geo_lng);

    }
    

}
