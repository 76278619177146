<nav class="navbar navbar-expand-md navbar-light">
    <div class="container-fluid">
      <div class="bg-navbar">
        <a class="navbar-brand" [routerLink]="['/']" [routerLinkActive]="['is-active']">
            <img class="img-fluid img-nav" src="assets/images/navbar/logo-nav.png" alt="">
        </a>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse edit" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['is-active']">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/nosotros']" [routerLinkActive]="['is-active']">Nosotros</a>
          </li>
          <li class="nav-item">
           
            <div class="dropdown select_dropdown">
              <a class="nav-link" [routerLink]="['/llantas']" [routerLinkActive]="['is-active']"  id="dropdownMenuButton1">Llantas</a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <div class="d-flex">
                  <li>
                    <a class="nav-link" [routerLink]="['/llantas', 'Automovil']" [routerLinkActive]="['is-active']">Automovil</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/llantas', 'UHP']" [routerLinkActive]="['is-active']">UHP</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/llantas', 'Camioneta deportiva']" [routerLinkActive]="['is-active']">Camioneta deportiva</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/llantas', 'SUV']" [routerLinkActive]="['is-active']">SUV</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/llantas', 'All terrain']" [routerLinkActive]="['is-active']">All terrain</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/llantas', '4X4']" [routerLinkActive]="['is-active']">4X4</a>
                  </li>
                </div>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/distribuidores']" [routerLinkActive]="['is-active']">Distribuidores</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/contacto']" [routerLinkActive]="['is-active']">Contacto</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
